@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");

@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  font-family: "Inter";
  margin: 0;
}

.dark .dark\:border-zinc-900 {	
  --tw-border-opacity: 1;	
  border-color: rgb(24 24 27/var(--tw-border-opacity))	
}	
.dark .dark\:bg-zinc-900 {	
  --tw-bg-opacity: 1;	
  background-color: rgb(24 24 27/var(--tw-bg-opacity))	
}	
.dark .dark\:bg-zinc-800 {	
  --tw-bg-opacity: 1;	
  background-color: rgb(39 39 42/var(--tw-bg-opacity))	
}	
.dark .dark\:bg-zinc-700 {	
  --tw-bg-opacity: 1;	
  background-color: rgb(63 63 70/var(--tw-bg-opacity))	
}	
.dark .dark\:text-white {	
  --tw-text-opacity: 1;	
  color: rgb(255 255 255/var(--tw-text-opacity))	
}	
.dark .dark\:hover\:bg-zinc-800:hover {	
  --tw-bg-opacity: 1;	
  background-color: rgb(39 39 42/var(--tw-bg-opacity))	
}